.container {
    flex: 1;
    background-color: #F8F6F6;
    font-family: var(--FontFamily-ExpletusSansRegular);
    min-height: 100vh;
}

.orderStepperImg {
    width: 100%;
    height: 319px;
    background-color: #fff;
}

.subContainer {
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.titlePage {
    color: #1A242D;
    text-align: center;
    font-family: var(--FontFamily-ExpletusSansRegular);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 60%;
}

.subContainer_2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
}

.stepperContainer {
    flex: 1;
    padding: 10px;
    margin-top: 30px;
}

.orderStepperTxt {
    color: #150554;
    margin: 0%;
    font-style: italic;
    margin-top: 20px;
}

@media (min-width: 1020px) {
    .titlePage {
        color: #1A242D;
        text-align: center;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 10%;
    }

    .subContainer_2 {
        padding-top: 0px;
    }

    .orderStepperImg {
        width: 100%;
        height: 418px;
        background-color: #fff;
    }

    .stepperContainer {
        display: flex;
        flex-direction: row;
        padding: 10px;
        margin-top: 30px;
    }

    .subContainer {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }

    .desktopFirstRowchild {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .desktopImage {
        width: 60%;
        height: 852px;
    }
}