.bm-card-container-A {
    display: flex;
    overflow: hidden;
    position: relative;
    flex-direction: row;
    width: 98%;
    margin: var(--s);
    margin-top: var(--xmd);
    margin-bottom: var(--xmd);
    background-color: var(--white);
    border-radius: var(--s);
    height: 160px;
    border: solid var(--unit) var(--indigo-c500);
    padding-top: var(--s);
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.bm-card-container-B {
    display: flex;
    flex-direction: row;
    position: relative;
    justify-content: space-between;
    padding-left: var(--s);
    padding-right: var(--s);
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.bm-card-img-wrapper-A {
    display: flex;
    justify-content: flex-start;
    height: var(--big);
    width: var(--xbig);
    background-color: var(--grey-c300);
    border-radius: var(--s);
    margin-left: var(--s);
    margin-right: var(--s);
}

.bm-card-img-wrapper-B {
    display: none;
}

.bm-basket-card-img-A>img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.bm-card-item-group-A {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 50%;
    text-align: left;
}

.bm-card-item-group-B {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: var(--s);
    height: 100%;
    width: 60%;
    text-align: left;
}

.bm-basket-card-header-wrapper-A {
    color: var(--indigo-c500);
}

.bm-basket-card-header-wrapper-B {
    color: var(--black-night);
    width: 50%;
}

.bm-basket-item-group-text-A {
    text-align: left;
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.item-group-text-A-title {
    font-family: var(--FontFamily-AvenirNextDemiBold);
}


.bm-basket-item-price-A {
    position: absolute;
    left: 0;
    bottom: 0;
    width: var(--giant);
    text-align: right;
}

.bm-basket-item-price-B {
    color: var(--black-night);
    font-weight: bold;
    align-content: center;
}

.bm-basket-item-price-A>p {
    position: absolute;
    flex-wrap: wrap;
    text-align: right !important;
    background-color: var(--indigo-c500);
    color: var(--white);
    font-size: var(--smd);
    padding: var(--ms);
    border-top-right-radius: var(--xxmd);
    bottom: -12px;
    left: 0;
    width: var(--xxbig);
}

.trash-icon-A,
.trash-icon-B {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--s);
    width: var(--l);
    height: var(--l);
    right: var(--s);
    top: inherit;
    cursor: pointer;
}


.trash-icon-mobile-A {
    background-color: var(--red-c400);
    border-radius: 50%;
    color: var(--white) !important;
}

.trash-icon-mobile-B {
    display: none;
}

.trash-icon-mobile-A i {
    color: var(--white) !important;
}

.bm-qty-up-down-A {
    width: var(--giant);
    margin-bottom: var(--xmd);
}


.separator-B {
    width: var(--xxl);
    border-bottom: var(--unit) solid var(--grey-c400-batleship);
    margin-top: var(--s);
}

@media (min-width: 768px) {
    /* Styles for screens larger than 768px */
}

@media (min-width: 1024px) {
    .bm-card-container-B {
        display: flex;
        height: var(--xmd-giant);
        padding: 0;
        padding-bottom: var(--xmd);
        padding-top: var(--xmd);
        border-bottom: var(--unit) solid var(--black);
        width: 45%;
        font-family: var(--FontFamily-ExpletusSansRegular);
        margin-bottom: var(--s);
        margin-top: var(--s);
    }

    .bm-card-item-group-B {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: var(--s);
        padding-right: 0;
        text-align: left;
    }

    .separator-B {
        display: none;
    }

    .bm-card-container-A {
        width: 45%;
    }

    .bm-card-img-wrapper-A {
        width: var(--giant);
    }

    .trash-icon-A,
    .trash-icon-B {
        width: var(--xl);
        height: var(--xl);
    }

    .trash-icon-A:hover,
    .trash-icon-B:hover {
        background-color: var(--red-c400);
        border-radius: 50%;
    }

    .trash-icon-B:hover {
        background-color: var(--white);
        color: var(--black-night) !important;
    }

    .trash-icon-A i,
    .trash-icon-B i {
        color: var(--black-night) !important;
    }

    .trash-icon-A:hover i {
        color: white !important;
    }

    .bm-basket-item-price-A {
        position: absolute;
        left: 0;
        bottom: 0;
        width: var(--giant);
        text-align: right;
    }

    .bm-basket-item-price-B {
        position: absolute;
        right: 0;
        bottom: 5%;
        width: var(--giant);
    }

    .bm-card-img-wrapper-B {
        display: flex;
        height: var(--big);
        width: var(--xbig);
        background-color: var(--grey-c300);
        margin-left: var(--s);
        margin-right: var(--s);
    }

    .bm-basket-card-img-B>img {
        border-radius: 0;
        width: var(--mgiant);
        height: var(--mgiant);
        object-fit: cover;
    }
}