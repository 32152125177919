.badge {
    position: absolute;
    cursor: pointer;
    display: flex;
    width: 20px;
    height: 20px;
    background-color: red;
    right: 0px;
    top: 20px;
    border-radius: 20px;
    justify-content: center;
    color: white;
    font-weight: 700;
    padding: 1px;
    font-size: 14px;
    align-items: center;
}