:root {
    --xl-giant: 400px;
    --l-giant: 350px;
    --xxmd-giant: 300px;
    --xmd-giant: 250px;
    --mgiant: 200px;
    --giant: 150px;
    --bigmd: 140px;
    --xxxbig: 130px;
    --xxbig: 120px;
    --xbig: 110px;
    --big: 100px;
    --xxh: 90px;
    --xh: 75px;
    --mhuge: 70px;
    --huge: 60px;
    --xxl: 50px;
    --xl: 40px;
    --l: 30px;
    --md: 25px;
    --xmd: 20px;
    --xxmd: 15px;
    --smd: 12px;
    --s: 10px;
    --ms: 8px;
    --xs: 5px;
    --xxs: 2px;
    --unit: 1px;

    /* Screen sizes */
    --media-tablet: 760px;
    --medi-desktop: 1020px;
}