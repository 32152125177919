/* AVENIR NEXT */
@font-face {
    font-family: "AvenirNextDemiBold";
    src: local("AvenirNextDemiBold"),
        url("@byme-ui-fonts/avenirs/AvenirNext-DemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "AvenirNextBold";
    src: local("AvenirNextBold"),
        url("@byme-ui-fonts/avenirs/AvenirNext-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "AvenirNextHeavy";
    src: local("AvenirNextHeavy"),
        url("@byme-ui-fonts/avenirs/AvenirNext-Heavy.ttf") format("truetype");
}

@font-face {
    font-family: "AvenirNextCyrLight";
    src: local("AvenirNextCyrLight"),
        url("@byme-ui-fonts/avenirs/AvenirNextCyr-Light.ttf") format("truetype");
}

@font-face {
    font-family: "AvenirNextRegular";
    src: local("AvenirNextRegular"),
        url("@byme-ui-fonts/avenirs/AvenirNextCyr-Regular.ttf") format("truetype");
}

/* EXPLETUS */
@font-face {
    font-family: "ExpletusSansBold";
    src: local("ExpletusSansBold"),
        url("@byme-ui-fonts/expletus/ExpletusSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansSemiBold";
    src: local("ExpletusSansSemiBold"),
        url("@byme-ui-fonts/expletus/ExpletusSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansRegular";
    src: local("ExpletusSansRegular"),
        url("@byme-ui-fonts/expletus/ExpletusSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansMedium";
    src: local("ExpletusSansMedium"),
        url("@byme-ui-fonts/expletus/ExpletusSans-Medium.ttf") format("truetype");
}



:root {
    /* AVENIR NEXT */
    --FontFamily-AvenirNextDemiBold: 'AvenirNextDemiBold';
    --FontFamily-AvenirNextBold: 'AvenirNextBold';
    --FontFamily-AvenirNextHeavy: 'AvenirNextHeavy';
    --FontFamily-AvenirNextLight: 'AvenirNextCyrLight';
    --FontFamily-AvenirNextRegular: 'AvenirNextRegular';

    /* EXPLETUS */

    --FontFamily-ExpletusSansMedium: 'ExpletusSansMedium';
    --FontFamily-ExpletusSansRegular: 'ExpletusSansRegular';
    --FontFamily-ExpletusSansSemiBold: 'ExpletusSansSemiBold';
    --FontFamily-ExpletusSansBold: 'ExpletusSansBold';
}