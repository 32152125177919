.container{
    display: flex;
    position:relative;
    padding: 50px;
    background-color: #fff;
}

.contentContainer{
    width: 100%;
    border: 2px solid black;
    padding-top: 20px;
    padding-left:5rem;
    padding-bottom: 15px;
}


.header{
    position:absolute;
    background-color: #333;;
    right:  -3.2rem;
    top: 25px;
   padding: 10px;
    width: 17.5rem;
}

.headerText{
    color: rgb(230, 203, 105);
    font-size: 50px;
    font-weight: 600;
    margin: 0;
    margin-left: 20px;
}

.firstRow{
    display: flex;
    flex-direction: row;
    padding: 5px;
    justify-content: space-between;
}

.logoBox{
  width: 20%;
  height: 10%;
  overflow: hidden;
}

.logo{
width: 100%;
height:100%;
object-fit: contain;
}

.billInfo{
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    margin-top: 10px;
    width: 15rem;
    justify-content: space-between;
}

.infoColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.infoSecondColumn{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.infoColumn label{
    margin-top: 5px;
    margin-bottom: 5px;
}
.infoSecondColumn label{
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 10px;
    text-align: right;
}
.line{
    border-bottom: 5px solid black;
}
.neutralLine{
    border-bottom: 5px solid transparent;
}

.userInfo{
    display: flex;
    flex-direction: column;
}

.factureNameFor{
    font-size: 30px;
    font-weight: 600;
    margin-top: 10px;
}

.customerName{
    font-size: 20px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}

.tableContainer{
    margin-right: 3rem;
    border: 1px solid black;
    padding-bottom: 20%;
    border-top: none;
    margin-bottom: 2rem;
}
/* Apply styles to the entire table */
.strippedTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 3rem;
  }
  
  /* Apply styles to the table headers (th) */
  .strippedTable th {
    background-color: #333;
    color: rgb(230, 203, 105);
    padding: 10px;
    text-align: left;
  }
  
  /* Apply styles to the table cells (td) */
  .strippedTable td {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 15px;
    padding-left: 10px;
  }
  
.lisiItemtext{
  font-size: 12px;
  font-weight: 400;
}
.strippedTable td>ol{
  align-items: center;
}

  /* Apply striped background to table rows (tbody tr) */
  .strippedTable tbody tr:nth-child(odd) {
    background-color: #fff; /* Light background color for odd rows */
  }
  
  .strippedTable tbody tr:nth-child(even) {
    background-color: #f2d562a3; /* White background color for even rows */
  }
  .listNumber{
   font-weight: 800;
   text-align: center;
  }

  .billInformationsRow{
    display: flex;
    justify-content: space-between;
    padding-right: 2rem;
  }

  .billInformationText{
    font-size: 15px;
    font-weight: bold;
    font-style: italic;
  }

  .itemColumn{
    display: flex;
    flex-direction: column;
  }
  .itemColumnSecond{
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  .itemColumn label{
    text-align: left;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .itemColumnSecond label{
    margin-bottom: 10px;
    font-weight: 600;
    margin-left: 10px;
  }

  .paymentInfoRow{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
  }

  .paymentColumn{
    display: flex;
    flex-direction: column;
  }

  .paymenTotal{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: rgba(243, 201, 129, 0.895);
    /* padding-right: 20px; */
    position:absolute;
    right: -3.2rem;
    top: 0px;
   padding: 10px;
    width: 20.5rem;
  }

  .paymentColumn label{
    margin-bottom: 10px;
    font-weight: 600;
  }

  .paymentTotalAmount{
    margin-right: 5rem;
  }

  .lastRow{
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
    padding-right: 10%;
  }

  .lastRowColumn{
    display: flex;
    flex-direction: column;
  }

  .lastRowColumn label{
    display: flex;
    font-size: 12px;
    margin-bottom: 5px;
    font-weight: 600;
    width: 100%;
    justify-content: center;
  }

.socialRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.socialRow label{
    margin-left: 5px;
    margin-right: 5px;
}

.lastRowColumn a{
    color: black;
    font-size: 15px;
   text-decoration: none;
}