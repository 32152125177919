.bm-tabbar-container {
    position: relative;
    width: 100%;
}

.bm-tabbar {
    display: flex;
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding-bottom: var(--ms);
    border-bottom: var(--unit) solid var(--grey-c200);
    scrollbar-width: none;
    /* Pour Firefox */
    -ms-overflow-style: none;
    /* Pour Edge */
}

.bm-tab {
    cursor: pointer;
    padding: var(--s) var(--xmd);
    transition: color 0.3s ease;
    /* Transition normale sans la bordure */
    margin-right: var(--ms);
    /* Ajout de marge entre les onglets */
}

.bm-tab>i {
    color: inherit !important;
}

.bm-tab.active {
    color: blue;
    position: relative;
    /* Définir la position relative pour le contenu */
}

/* on adapte la couleur des icones en heritant de celui du parent */
.bm-tab.active>i {
    color: inherit !important;
}

.bm-tab.active::after {
    position: absolute;
    bottom: -2px;
    /* Ajuster la position de la bordure pour qu'elle se superpose à la bordure inférieure */
    left: 0;
    width: 100%;
    height: var(--xxs);
    background-color: blue;
    animation: bounce 0.5s;
    /* Appliquer l'animation uniquement à la bordure */
}

.no-indicator .bm-tab.active::after {
    display: none;
    /* Hide the indicator when the no-indicator class is present */
}

.bm-tabbar.left .bm-tab-header {
    margin-left: var(--ms);
}

.bm-tabbar.right .bm-tab-header {
    margin-right: var(--ms);
}

.bm-tab-icon {
    margin-right: var(--ms);
}

.bm-tab-header {
    font-size: var(--xxmd);
    /* Définition de la taille de police à var(--xxmd) */
}

.bm-tab-content {
    padding: var(--xmd);
}

.bm-tabbar.left .bm-tab-header {
    position: relative;
    z-index: 1;
}

.bm-tabbar.right .bm-tab-header {
    position: relative;
    z-index: 1;
}

/* Styles pour la template A de la tabbar */
.bm-tabbar-A {
    font-family: var(--FontFamily-ExpletusSansBold);
    background-color: var(--grey-c100);
    border-radius: var(--s);
    padding: var(--s);
}

.bm-tabbar-A .bm-tab {
    background-color: transparent;
    padding: var(--s) var(--xmd);
    transition: background-color 0.3s ease;
}

.bm-tabbar-A .bm-tab:not(.active):hover {
    background-color: var(--red-c400) !important;
    color: var(--white);
}

.bm-tabbar-A .bm-tab.active {
    background-color: var(--red-c400);
    color: var(--white);
    font-weight: 700;
    font-size: var(--smd);
    margin-left: var(--s);
    margin-right: var(--s);
    padding: var(--s) var(--xmd);
    /* Ajustement du padding */
    border-radius: var(--xmd);
    white-space: nowrap;
    cursor: pointer;
}

.bm-tabbar-A .bm-tab:not(.active) {
    background-color: var(--white);
    color: var(--red-c400);
    font-weight: 700;
    font-size: var(--smd);
    margin-left: var(--s);
    margin-right: var(--s);
    padding: var(--s);
    padding-left: var(--xmd);
    padding-right: var(--xmd);
    border-radius: var(--xmd);
    white-space: nowrap;
    cursor: pointer;
}

/* Styles pour la template B de la tabbar */
.bm-tabbar-B {
    font-family: var(--FontFamily-ExpletusSansBold);
    background-color: var(--white);
    border-radius: var(--s);
    padding: var(--s);
}

.bm-tabbar-B .bm-tab {
    background-color: transparent;
    padding: var(--s) var(--xmd);
    transition: background-color 0.3s ease;
}

.bm-tabbar-B .bm-tab:not(.active):hover {
    background-color: var(--orange-c400) !important;
    color: var(--white);
}

.bm-tabbar-B .bm-tab.active {
    background-color: var(--orange-c300);
    color: var(--white);
    font-weight: 700;
    font-size: var(--xxmd);
    margin-left: var(--s);
    margin-right: var(--s);
    padding: var(--ms);
    padding-left: var(--smd);
    padding-right: var(--smd);
    border-radius: var(--s);
    white-space: nowrap;
    cursor: pointer;
}

.bm-tabbar-B .bm-tab:not(.active) {
    background-color: var(--grey-c200);
    font-family: var(--FontFamily-ExpletusSansRegular);
    color: var(--grey-c400);
    font-weight: 700;
    font-size: var(--xxmd);
    margin-left: var(--s);
    margin-right: var(--s);
    padding: var(--xs);
    padding: var(--ms);
    padding-left: var(--smd);
    padding-right: var(--smd);
    border-radius: var(--s);
    white-space: nowrap;
    cursor: pointer;
}