@font-face {
    font-family: "ExpletusSansBold";
    src: local("ExpletusSansBold"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansRegular";
    src: local("ExpletusSansRegular"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Regular.ttf") format("truetype");
}


.container {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 500px;
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
    /* remove later */
    margin-left: 20px;
    border-radius: 10px;
    overflow: hidden;
}

.imageContainer {
    position: relative;
    height: 50%;
}

.imageContainer>h3 {
    position: absolute;
    top: 10px;
    left:30px;
    font-size:15px;
    color: #fff;
    z-index: 99;
    font-family: "ExpletusSansRegular";
}

.cardImg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.517);
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.362);
    z-index: 1;
}

.cardSubcontainer {
    width: auto;
    height: 50%;
    background-color: #949494;
}

.subContainerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    align-items: baseline;
}

.subContainerRow :nth-child(1) {
    margin-left: 10px;
    color:#fff;
    font-family: "ExpletusSansBold";
}

.subContainerRow :nth-child(2) {
    margin-right: 12px;
    font-family: "ExpletusSansRegular";
    color:#D9D9D9;;
    font-size: 10px;
    border-bottom: 1px solid #D9D9D9;
    font-weight: 700;
    cursor: pointer;

}
.productsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
    margin-bottom: 20px;
}

@media (min-width: 760px) {}

@media (min-width: 1020px) {

    .container {
        width: 520px;
        height: 640px;
    }

}
