.bm-fixed-bottom-container-A {
    position: fixed;
    z-index: 999;
    bottom: 12%;
    width: 95%;
    transform: translate(0%, 50%);
    display: flex;
    justify-content: center;
    background: none;
    box-shadow: none;
    padding-top: var(--md);
    font-family: var(--FontFamily-AvenirNextDemiBold);
}

.bm-fixed-bottom-container-B {
    background: none;
    box-shadow: none;
    justify-content: center;
    font-family: var(--FontFamily-ExpletusSansBold);
}

.bm-fixed-bottom-section-container-A {
    position: relative;
    width: 100%;
    border: solid var(--xxs) var(--red-c400);
    background-color: var(--white);
    border-radius: var(--xmd);
}

.bm-fixed-bottom-section-container-B {
    width: 100%;
    border-radius: var(--s);
    background-color: var(--white);
    padding-left: var(--s);
    padding-right: var(--s);
}

.bm-add-product-icon-container-A {
    display: flex;
    z-index: 9999;
    position: absolute;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    top: -25px;
    width: var(--huge);
    height: var(--huge);
    left: 40%;
    background-color: var(--red-c400);
}

.bm-add-product-icon-container-B {
    display: none;
}

.bm-add-product-icon-container-A i {
    background-color: white;
    padding: var(--s);
    border-radius: 50%;
    font-weight: bold;
    cursor: pointer !important;
}

.bm-add-product-icon-A {
    width: var(--xxl);
}

.bm-fixed-bottom-container-subheader-A {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: var(--xmd);
}

.bm-total-container-A {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--s);
    margin-bottom: var(--s);
    color: var(--indigo-c500);
}

.bm-total-container-B {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: var(--black-night) !important;
}

.bm-total-label-A {
    margin: 0%;
    margin-left: var(--s);
    font-size: 12px;

}

.bm-total-amount-A {
    margin: 0%;
    margin-right: var(--s);
    font-size: 16px;
}

.bm-floating-section-button-A,
.bm-floating-section-button-B {
    padding-left: var(--xmd);
    padding-right: var(--xmd);
}
.bm-floating-section-button-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

@media (min-width: 760px) {
    .bm-fixed-bottom-container-A {
        bottom: 10%;
        left: 15%;
        transform: translate(0%, 50%);
        align-self: center;
        width: 70%;
    }

    .bm-fixed-bottom-section-container-A {
        position: relative;
        border-radius: var(--xmd);
        width: 100%;
    }

    .bm-fixed-bottom-section-container-B {
        background-color: var(--white);
        width: 70%;
    }

    .bm-add-product-icon-container-A {
        left: 45%;
    }

    .bm-add-product-icon-A {
        width: var(--mhuge);
    }

    .bm-total-container-A {
        margin-top: var(--xmd);
        margin-bottom: var(--xmd);
    }

    .bm-total-container-B {
        margin-bottom: var(--xmd);
    }

    .bm-total-label-A {
        margin-left: var(--xmd);
        font-size: 16px;
    }

    .bm-total-amount-A {
        margin-right: var(--xmd);
        font-size: 16px;
    }
}

@media (min-width: 1020px) {
    .bm-fixed-bottom-container-A {
        width: 40%;
        bottom: 50%;
        left: 59%;
        transform: translate(0%, 50%);
        justify-content: center;
    }

    .bm-fixed-bottom-container-B {
        position: fixed;
        width: 40%;
        bottom: 60%;
        right: 0;
        transform: translate(0%, 50%);
        justify-content: center;
        background-color: var(--white);
    }

    .bm-fixed-bottom-section-container-A {
        width: 100%;
        border-radius: var(--xmd);
        right: var(--s);
    }

    .bm-fixed-bottom-section-container-B {
        width: 100%;
    }

    .bm-add-product-icon-container-A i {
        cursor: pointer !important;
    }
}