.header-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
}
.bm-modal-title{
    color: var(--black);
}
.icon-container-right {
    position: absolute;
    right: 0;
}

.icon-container-left {
    position: absolute;
    left: 0;
}
