.container {
    width: 100%;
}

.imgBackground {
    width: 100%;
    height: 150px;
    border-radius: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (min-width: 760px) {

    .imgBackground {
        height: 300px;
        border-radius: 20px;
    }

}