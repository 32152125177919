/* styles.css */
  .header-shadow {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
  }
  
.bm-header-container {
    display: flex;
    flex-direction: column;
    padding: var(--s);
    margin-bottom: var(--smd);
    width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1000;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease;
}

.bm-header-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-bottom: var(--s);
}

.bm-header-go-back-label-A {
    font-family: var(--FontFamily-AvenirNextDemiBold);
    font-size: var(--xmd);
    color: var(--indigo-c500);
}

.bm-header-go-back-label-B {
    font-family: var(--FontFamily-ExpletusSansSemiBold);
    font-size: var(--xmd);
    color: var(--orange-c400)
}

.bm-header-back-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: var(--s);
}

.subRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bm-header-back-button-both {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: var(--s);
    width: 100%;
}

.bm-header-back-button-with-home {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-content: flex-end;
    justify-content: flex-end;
    margin-left: var(--s);
}


.bm-header-logo {
    cursor: pointer;
}

.bm-right-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 var(--s);
}

.bm-input-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    max-width: 600px;
}

.bm-input-icon-container input {
    flex-grow: 1;
    box-sizing: border-box;
}

.bm-header-basket-container {
    /* cursor: pointer; */
    display: flex;
    align-items: center;
    margin-left: var(--md);
}

@media (min-width: 768px) {
    .bm-header-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .bm-input-icon-container {
        margin-left: var(--xmd);
        margin-right: var(--xmd);
    }

    .bm-header-logo-container {
        flex: 0;
        justify-content: flex-start;
        margin-bottom: 0;
    }

    .bm-right-container {
        flex: 1;
        justify-content: flex-end;
    }
}

@media (min-width: 1024px) {
    .bm-right-container {
        flex: 1;
        justify-content: flex-end;
    }

    .bm-input-icon-container {
        margin-left: var(--xmd);
        margin-right: var(--xmd);
    }
}