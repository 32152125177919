.container {
    width: 100%;
    padding-bottom: 20px;
}

.bm-delivery-divider-A {
    display: none;
}

.bm-delivery-divider-B {
    display: none;
}

.pageTitle {
    color: var(--indigo-c500);
    margin: 0%;
    text-align: center;
    margin-left: 120px;
    margin-top: 80px;
    font-size: 20px;
}

.bm-payments-type-container-A {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}

.bm-payments-type-container-B {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
}

.paymentWrapper {
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
}

.bm-payment-btn-B {
    display: none;
}

.paymentTypeItemContainer_1 {
    width: 80%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px #e7e7e7;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 5px;
    background-color: #fff;
}

.paymentTypeItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.paymentTypeLabel {
    margin: 0%;
    color: var(--indigo-c500);
}

.mobilPaymentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobilPaymentItemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

.unCheckedCustom {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    border: solid 1px #c2c2c2;
}

.checkedCustom {
    width: 15px;
    height: 15px;
    border-radius: 5px;
    background-color: var(--indigo-c500);
    border: solid 1px var(--indigo-c500);
}

.mobilPaymentLabel {
    margin: 0%;
    font-size: 8px;
    color: #c2c2c2;
}

.mobilPaymentLabelSelected {
    margin: 0%;
    font-size: 8px;
    color: var(--indigo-c500);
}

.bm-floating-recap-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
}


.bm-floating-recap-content p {
    text-align: center;
    font-size: 14px;
    font-family: 'ExpletusSansRegular';
}

@media (min-width: 760px) {

    .pageTitle {
        margin-left: 250px;
        font-size: 25px;
    }

    .bm-payments-type-container-A {
        margin-top: 100px;
    }

    .paymentTypeItemContainer_1 {
        width: 70%;
    }

    .paymentTypeLabel {
        font-size: 20px;
    }

    .mobilPaymentItemContainer {
        margin-top: 20px;
    }

    .checked {
        width: 50px;
    }

    .unCheckedCustom {
        width: 25px;
        height: 25px;
        border-radius: 10px;
    }

    .checkedCustom {
        width: 25px;
        height: 25px;
        border-radius: 10px;
    }

    .mobilPaymentLabel {
        font-size: 12px;
    }

    .mobilPaymentLabelSelected {
        font-size: 12px;
    }

}

@media (min-width: 1020px) {
    .paymentWrapper {
        width: 50%;
    }

    .pageTitle {
        margin-left: 0px;
        font-size: 30px;
        margin-top: 0px;
    }

    .bm-payments-type-container-A {
        margin-top: 50px;
    }

    .desktopLeftIcon {
        margin: 20px;
        width: 70px;
    }

    .bm-delivery-divider-B {
        display: block;
        padding-bottom: 20px;
    }
}