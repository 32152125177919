.container {
    flex: 1;
    width: 100%;
    min-height: 100vh;
    padding-top: 20px;
}

.logoContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
}

.logoStyle {
    width: 200px;
    height: auto;
    object-fit: contain;
}

.inputStyle {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.BrandActivitySliderPosition {
    margin-top: -45px;
}
.brandActivitySelectorPosition {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 100px;
}
.brandActivitySelectorPositionTitle {
    margin-right: 20px;
    font-weight: 700;
}
.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 50px;
}
.sectionTitle {
    margin: 0%;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
}
.sectionSubTitle {
    margin: 0%;
    color: grey;
    font-style: italic;
    text-align: center;
    font-size: 12px;
}
.avtivityGroupName {
    margin: 0%;
    margin-left: 30px;
    margin-bottom: -10px;
    color: grey;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {

    .container {
        padding-top: 0px;
    }
    .sectionContainer {
        margin-bottom: 40px;
        margin-top: 100px;
    }
    .sectionTitle {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .sectionSubTitle {
        font-size: 14px;
    }

}
