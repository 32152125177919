.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carouselContainer {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 60px;
}

.productsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: var(--xmd);
}

.categoryContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
}

.categoryNameStyle {
    margin: 0%;
    font-size: 18px !important;
    color: var(--indigo-c500);
    margin-bottom: 10px;
    font-family: var(--FontFamily-AvenirNextHeavy);
}

.buttoncontainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.categoryHeaderRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-right: var(--xmd);
    padding-left: var(--xmd);
}

.noItemText {
    text-align: center;
    margin-top: var(--l);
    font-size: var(--xmd) !important;
    margin-bottom: 10px;
}

@media (min-width: 760px) {

    .carouselContainer {
        margin-top: 40px;
        margin-bottom: 80px;
    }

    .categoryNameStyle {
        margin: 0%;
        /* margin-left: 20px; */
        font-size: 30px;
        color: var(--indigo-c500);
        margin-bottom: 10px;
        font-family: var(--FontFamily-AvenirNextHeavy);
    }

}

@media (min-width: 1020px) {
    .noItemText {
        font-size: var(--md) !important;
    }

    .productsContainer {
        margin-top: 100px;
    }

    .buttoncontainer {
        margin-top: 40px;
    }

    .categoryContainer {
        margin-bottom: 100px;
    }

}