.container {
    flex: 1;
    width: 100%;
    min-height: 100vh;
}

.categoryContentContainer {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.categoryContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    justify-content: flex-end;
    align-items: flex-start;
}

.categoryDarkScreen {
    width: inherit;
    height: inherit;
    background-color: #00000055;
    position: absolute;
}

.categoriesNameTxt {
    z-index: 99;
    margin: 0%;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
    margin-right: 15px;
    color: #fff;
}

.productsContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;

}

@media (min-width: 760px) {
    .categoryContainer {
        width: 100%;
        height: 270px;
    }

    .categoriesNameTxt {
        font-size: 22px;
        margin-top: 10px;
        margin-right: 25px;
    }
}

@media (min-width: 1020px) {
    .categoryContainer {
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: relative;
    }

    .desktopDarkScreen {
        width: 100%;
        height: 60vh;
        position: absolute;
        background-color: #00000055;
    }

    .desktopCategoryHeadContainer {
        position: absolute;
        width: 100%;
        margin-top: 20px;
        z-index: 99;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logoStyle {
        object-fit: cover;
        border-radius: 10px;
        width: 100px;
        height: auto;
        margin-left: 40px;
    }

    .inputAndCartContainer {
        display: flex;
        align-items: center;
        margin-right: 40px;
    }

    .carteStyle {
        width: 60px;
    }

    .desktopCategoryName {
        color: #fff;
        margin: 0%;
        position: absolute;
        z-index: 99;
        bottom: 0;
        margin-bottom: 20px;
        margin-left: 40px;
        font-size: 25px;
    }

}