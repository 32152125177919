:root {

    /* Basics */
    --white: #FFFFFF;
    --black: #000;
    --black-night: #0A0A0A;

    /* Purple */
    --purple-c300: #7B2CBF;
    --purple-c300-rgba: rgba(122, 44, 191, 0.3);
    --purle-c400: #752678;
    --purple-c500: #170745;

    /* Grey */
    --grey-c100: #FAFAFA;
    --grey-c200: #E6E6E6;
    --grey-c300: #C4C4C4;
    --grey-c400: #AEB1C1;
    --grey-c400-batleship: #909090;
    --grey-silver: #CECBCB;
    --grey-c500: #7F7F7F;
    --grey-c500-jet: #2C2C2C;


    /* pink */
    --pink-c300: #F49D9D;

    /* Red */
    --red-c400: rgb(236, 91, 91);
    --red-c500: #BC1616;

    /* Indigo */
    --indigo-c500: #1A4777;
    --indigo-c500-rgba: rgba(26, 71, 119, 0.456);

    /* Orange */
    --orange-c300: #F6D18D;
    --orange-c300-rgb: rgba(246, 209, 141, 0.4);
    --orange-c400: #F2BD5B;
}