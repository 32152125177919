.container {
    position: relative;
    z-index: 99;
    width: 350px;
    background-color: var(--white);
    border-radius: var(--xxmd);
    -webkit-box-shadow: 0px 0px 14px -3px var(--black); 
    box-shadow: 0px 0px 14px -3px var(--black);
}

.selectedElementContainer {
    width: 100%;
    height: 80px;
    border-radius: var(--xxmd);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    cursor: pointer;
}
.selectedNameContainer {
    width: 100%;
    height: 100%;
    background-color: #00000061;
    color: var(--white);
    font-weight: 700;
    display: flex;
    align-items: center;
    padding-left: var(--xmd);
    font-size: var(--md);
    cursor: pointer;
}

.activitiesListSelectContainer {
    position: absolute;
    z-index: 9999;
    width: 100%;
    max-height: var(--xmd-giant);
    overflow-y: scroll;
    padding-bottom: var(--l);
    cursor: pointer;
    background-color: var(--white);
    border-bottom-left-radius: var(--xxmd);
    border-bottom-right-radius: var(--xxmd);
}

.activityItemContainer {
    width: auto;
    height: 80px;
    margin-bottom: 3px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.activityItemNameContainer {
    width: 'auto';
    height: 100%;
    background-color: #00000045;
    color: var(--white);
    font-weight: 700;
    padding-left: var(--xmd);
    font-size: var(--xmd);
    display: flex;
    align-items: center;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {



}
