.container {
    /* display: flex; */

}

.bm-product-bg-img-A {
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.bm-product-bg-img-B {
    position: relative;
    height: 30vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom: var(--l);
    overflow: hidden;
}

.bm-product-bg-img-B .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.productImg {
    width: 100%;
    height: 70vh;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.iconLeft {
    width: 50px;
    height: 50px;
    margin: var(--s);
    border-radius: 50px;
    -webkit-box-shadow: 0px 0px 12px -4px var(--black);
    box-shadow: 0px 0px 12px -4px var(--black);
}

.bm-product-details-container-A {
    position: relative;
    z-index: 99;
    width: 100%;
    border-top-right-radius: var(--l);
    border-top-left-radius: var(--l);
    padding-top: var(--xmd);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.bm-product-details-container-B {
    position: relative;
    margin-left: var(--l);
}

.bm-product-details-container-B :nth-child(2) {
    margin-top: var(--xxmd);
    margin-bottom: var(--xxmd);
}

.bm-separator-container {
    position: sticky;
    z-index: 999;
    background-color: var(--white);
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: var(--s);
    padding-bottom: var(--xmd);
}

.bm-separator-element {
    background-color: var(--indigo-c500);
    width: var(--mhuge);
    height: var(--xs);
    border-radius: 50px;
}


.bm-product-price-A {
    background-color: var(--indigo-c500);
    color: var(--white) !important;
    padding: var(--s);
    font-size: var(--xxmd) !important;
    padding: var(--xxmd);
    width: 60%;
    padding-right: var(--xxmd);
    padding-left: var(--xxmd);
    border-top-right-radius: var(--l);
    border-bottom-right-radius: var(--l);
    margin-top: var(--big);
}


.bm-details-sub-container-A{
    width: var(--xmd-giant);
}
.bm-details-sub-container-B {
    display: flex;
     width: var(--xxmd-giant);
    flex-direction: column-reverse;
}

.bm-details-sub-container-B p {
    color: var(--black) !important;
}

.bm-product-name-A {
    margin: 0%;
    margin-left: var(--s);
    font-size: var(--md) !important;
    color: var(--indigo-c500);
    margin-top: var(--xmd);
}

.bm-product-description-A {
    margin: 0%;
    margin-left: var(--xmd) !important;
    margin-right: var(--s) !important;
    font-style: italic;
    color: #AEB1C1;
    font-size: 12;
    margin-top: var(--s);
}

.bm-action-btn-grp-container {
    margin-top: var(--xl);
    margin-bottom: var(--xl);
}

/* suggestion */
.bm-product-suggestion-container {
    /* background-color: #fff; */
    width: 100%;
    padding-bottom: var(--xmd);
}

.bm-product-suggestion-title-A {
    margin: 0%;
    font-size: var(--xmd);
    color: var(--indigo-c500);
    margin-left: var(--xmd);
    padding-top: var(--s);
    margin-bottom: var(--s);
}

.bm-product-suggestion-title-B {
    font-family: var(--FontFamily-ExpletusSansBold);
    font-size: var(--xmd);
    color: var(--black) !important;
    margin-left: var(--xmd);
}

.suggestionProductSubContainer {
    display: flex;
    overflow-x: scroll;
}

.suggestionProductSubContainer::-webkit-scrollbar {
    display: none;
}

.suggestionProductSubContainer>* {
    flex-shrink: 0;
}

.bm-product-suggestion-item-container {
    margin: var(--s)
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
        opacity: 0;
    }

    to {
        transform: translateY(10%);
        opacity: 1;
    }
}

.bm-product-content-container-A {
    position: absolute;
    bottom: -9%;
    left: 0;
    right: 0;
    background-color: var(--white);
    padding-top: 0;
    border-radius: var(--ms);
    height: 50%;
    max-height: 90%;
    overflow: auto;
    /* Permet le défilement vertical et horizontal */
    transition: height 0.3s ease;
    /* Animation pour le changement de hauteur */
    box-sizing: border-box;
    /* Inclut le padding et la bordure dans la largeur et la hauteur */
    animation: slideUp 1s;
    border-top-right-radius: var(--xxmd);
    border-top-left-radius: var(--xxmd);
}


.bm-product-content-container-B .bm-separator-element {
    display: none;
}

.bm-product-price-B {
    font-size: var(--xmd) !important;
    font-family: var(--FontFamily-ExpletusSansSemiBold);
}

.bm-quantity-up-down-wrapper {
    display: flex;
    margin-left: var(--xmd);
    margin-right: var(--xmd);
}

@media (min-width: 760px) {

    .bm-product-details-container-A {
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        padding-top: var(--xmd);
    }

    .bm-separator-container {
        top: var(--xmd);
    }

    .bm-separator-element {
        width: var(--big);
        height: var(--s);
        border-radius: 50px;
    }

   

    .bm-product-price-B {
        font-size: var(--md) !important;
        font-family: var(--FontFamily-ExpletusSansSemiBold);
    }

    .bm-product-name-A {
        margin-left: var(--xmd);
        margin-top: var(--xmd);
    }

    .bm-product-name-B {
        font-size: var(--md) !important;
        font-family: var(--FontFamily-ExpletusSansBold);
    }

    .bm-product-description-A {
        margin-left: var(--xmd);
        margin-right: var(--xmd);
        font-size: var(--xxmd);
    }

}

@media (min-width: 1020px) {

    .bm-desktop-details-row-A,
    .bm-desktop-details-row-B {
        display: flex;
        position: relative;
        flex-direction: row;
    }

    .bm-desktop-details-row-B {
        justify-content: space-between;
    }

    .bm-desktop-details-row-sub-container-B {
        align-self: center;
        margin-right: 10%;
    }

    .bm-desktop-details-row-sub-container-B:nth-child(2) p {
        margin-top: var(--s);
        margin-bottom: var(--s);
    }

    .bm-product-bg-img-A,
    .bm-product-bg-img-B {
        position: relative;
        height: 50vh;
        width: 60%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-bottom: var(--l);
        border-top-right-radius: var(--xxmd);
        border-bottom-right-radius: var(--xxmd);
        overflow: hidden;
    }

    .bm-product-bg-img-B {
        height: 45vh;
        width: 50%;
        border-radius: 0;
    }

    .bm-product-suggestion-title-A,
    .bm-product-suggestion-title-B {
        font-size: var(--md) !important;
    }

    .bm-product-content-container-A {
        position: relative;
        display: flex;
        height: 10%;
        max-height: 12%;
        padding-top: 0;
        border-radius: var(--ms);
        overflow: auto;
    }
    .bm-product-price-A {
        font-size: var(--xxmd);
        background-color: var(--indigo-c500);
        color: #fff !important;
        padding: var(--xmd);
        width: 70%;
        padding-right: var(--xmd);
        padding-left: var(--xmd);
        border-top-right-radius: var(--l);
        border-bottom-right-radius: var(--l);
        margin-top: var(--xl);
    }
}