.container {
    position: relative;
    overflow: hidden;
    height: 100px;
    padding-top: 20px;
    background-color: #0000002c;
}

.activeCustomSlide {
    position: relative;
    z-index: 99;
    width: 75px;
    height: 75px;
    border-radius: 75px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.customSlide {
    position: relative;
    z-index: 99;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.itemImgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlayCircle {
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: solid 5px #000000;
    width: 90px;
    height: 90px;
    border-radius: 90px;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {



}
