.container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}
.subContainer {
    width: 86%;
}

.customSlide {
    width: 80px;
    height: 50px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
    overflow: hidden;
}
.productItemStyles {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nextArrowStyles {
    position: absolute;
    top: 30%;
    right: -20px;
    color: #9C9E9D;
    font-size: 20px;
    font-weight: 700;
}
.prevArrowStyles {
    position: absolute;
    top: 30%;
    left: -20px;
    color: #9C9E9D;
    font-size: 20px;
    font-weight: 700;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {

    .container {
        margin-top: 40px;
        margin-bottom: 60px;
    }

    .subContainer {
        width: 90%;
    }

    .customSlide {
        width: 200px;
        height: 120px;
        margin-left: 10px;
        margin-right: 10px;
    }

}
