.cardContainer {
    width: var(--xxh);
    height: var(--xxxbig);
    flex-shrink: 0;
    border-radius: var(--s);
    background-color: white;
    overflow: hidden;
    padding-bottom: var(--s);
    cursor: pointer;
}

.cardContainer>div p {
    font-size: var(--s);
    margin: 0px;
    margin-left: var(--xs);
}

.cardContainer>div :nth-child(1) {
    margin-top: var(--xxs);
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.cardContainer>div :nth-child(2) {
    font-family: var(--FontFamily-ExpletusSansBold);
}

.cardImage {
    width: 100%;
    height: 70%;
    object-fit: cover;
}

@media (min-width: 760px) {}

@media (min-width: 1020px) {

    .cardContainer {
        width: var(--giant);
        height: var(--mgiant);
        flex-shrink: 0;
        border-radius: var(--s);
        background-color: white;
        overflow: hidden;
        padding-bottom: var(--s);
        cursor: pointer;
    }

}