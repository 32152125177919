.container {
    display: flex;
    justify-content: center;
    margin-top: var(--xxmd);
    margin-bottom: var(--xl);
}
.subContainer {
    width: 86%;
}

.customSlide {
    width: 80px;
    height: var(--xxl);
    border-radius: var(--s);
    margin-left: var(--xs);
    margin-right: var(--xs);
    overflow: hidden;
}
.productItemStyles {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nextArrowStyles {
    position: absolute;
    top: 30%;
    right: -15px;
    color: #9C9E9D;
    font-size: var(--xxmd);
    font-weight: 700;
}
.prevArrowStyles {
    position: absolute;
    top: 30%;
    left: -15px;
    color: #9C9E9D;
    font-size: var(--xxmd);
    font-weight: 700;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {

    .container {
        margin-top: var(--xl);
        margin-bottom: var(--huge);
    }

    .subContainer {
        width: 90%;
    }

    .customSlide {
        width: var(--mgiant);
        height: var(--xxbig);
        margin-left: var(--s);
        margin-right: var(--s);
    }

}
