.productItemContainer {
    width: 180px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: #ffffff;
    /* -webkit-box-shadow: 1px 1px 10px -2px lightgrey; 
    box-shadow: 1px 1px 10px -2px lightgrey; */
}

.productItemHeadContainer {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    background-color: #FAFAFA;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: end;
}

.productItemBodyContainer {
    padding: 10px;
}

.productItemName {
    margin: 0%;
    font-size: 12px;
    margin-bottom: 5px;
    color: #333542;
}

.productItemDescription {
    margin: 0%;
    font-size: 10px;
    margin-bottom: 10px;
    color: #6B7C93;
}

.productItemPrice {
    margin: 0%;
    text-align: center;
    font-size: 14px;
    color: #0A0C0B;
    font-weight: 700;
    margin-bottom: 10px;
}

.actionsButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 768px) {
    
}

@media (min-width: 1024px) {
    
}