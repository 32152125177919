.wrapper-container {
    display: flex;
    background-color: var(--white);
    border-radius: var(--s);
    padding: var(--s);
    margin: var(--s);
}

.wrapper-container-with-shadow,
.wrapper-container-with-shadow-B {
    box-shadow: 0 4px var(--ms) rgba(0, 0, 0, 0.1) !important;
}

.wrapper-container-row {
    position: relative;
    flex-direction: row;
    overflow: auto;
    scrollbar-width: none;
}

.wrapper-container-column {
    flex-direction: column;
    overflow: auto;
}