.container {
    background-color: #150554;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 30px;
    font-size: 12px;
    cursor: pointer;
}