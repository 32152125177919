.container {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 500px;
    align-self: center;
    margin-top: var(--xxmd);
    margin-bottom: var(--xxmd);
    /* remove later */
    margin-left: var(--xxmd);
    border-radius: var(--s);
    overflow: hidden;
}

.imageContainer {
    position: relative;
    height: 50%;
}

.imageContainer>h3 {
    position: absolute;
    top: var(--s);
    left:var(--l);
    font-size:var(--xxmd);
    color: var(--white);
    z-index: 99;
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.cardImg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.517);
    object-fit: cover;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.362);
    z-index: 1;
}

.cardSubcontainer {
    width: auto;
    height: 50%;
    background-color: #949494;
}

.subContainerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: var(--s);
    align-items: baseline;
}

.subContainerRow :nth-child(1) {
    margin-left: var(--s);
    color:var(--white);
    font-family: var(--FontFamily-ExpletusSansBold);
}

.subContainerRow :nth-child(2) {
    margin-right: 12px;
    font-family: var(--FontFamily-ExpletusSansRegular);
    color:#D9D9D9;;
    font-size: var(--s);
    border-bottom: var(--unit) solid #D9D9D9;
    font-weight: 700;
    cursor: pointer;

}
.productsWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: var(--xxmd);
    margin-bottom: var(--xxmd);
}

@media (min-width: 760px) {}

@media (min-width: 1020px) {

    .container {
        width: 520px;
        height: 640px;
    }

}