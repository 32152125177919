
.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.stepperContainerFlex {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepperContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepElement {
    margin: 0%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
}

.separatorLine {
    flex: 1;
    width: 2px;
}

.activeElement {
    text-align: center;
    font-size: 16px;
    margin: 0%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    animation: 1.5s ease 0s infinite beat;
}

@keyframes beat {
    0%, 50%, 100% { transform: scale(1, 1); }
    30%, 80% { transform: scale(0.90, 0.92); }
}

@media (min-width: 1024px) {
    .container {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
    }
    
    .stepperContainerFlex {
        flex-direction: row;
    }
    
    .stepperContainer {
        flex-direction: row;
    }
    
    .stepElement {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 14px;
    }
    
    .separatorLine {
        height: 2px;
    }
    
    .activeElement {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 20px;
    }
}