.container {
    position: relative;
    overflow: hidden;
    height: 520px;
}

.nextArrowStyles {
    position: absolute;
    z-index: 9999;
    top: 40%;
    right: -20px;
    color: #fff;
    background-color: #0000006b;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.prevArrowStyles {
    position: absolute;
    z-index: 9999;
    top: 40%;
    left: -5px;
    color: #fff;
    background-color: #0000006b;
    border-radius: 50px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {

    .container {
        height: 660px;
    }

    .subContainer {
        width: 94%;
    }

}
