.container {
    position: relative;
    height: 130px;
    overflow: hidden;
}
.activeCustomSlide {
    position: relative;
    width: 260px;
    height: 110px;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.customSlide {
    position: relative;
    width: 260px;
    height: 110px;
    border-radius: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.activeCustomDot {
    width: 15px;
    height: 3px;
    flex-shrink: 0;
    border-radius: 3px;
    background: #9C9E9D;
}
.customDot {
    width: 15px;
    height: 3px;
    flex-shrink: 0;
    border-radius: 3px;
    background: #D9D9D9;
}

.imgStles {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.detailsContainer {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: start;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(103deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 70%);
}
.itemTitle {
    margin: 0%;
    margin-top: 25px;
    margin-left: 15px;
    color: #FFF;
    font-size: 17px;
    font-weight: 700;
}
.itemSubTitle {
    margin: 0%;
    margin-top: 5px;
    margin-left: 15px;
    color: #E5E5E5;
    font-size: 10px;
    font-weight: 700;
}
.itemButton {
    margin: 0%;
    margin-top: 15px;
    margin-left: 15px;
    background-color: #fff;
    color: #2C2B2B;
    font-size: 10px;
    font-weight: 700;
    padding: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 20px;
    cursor: pointer;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {



}
