@font-face {
    font-family: "ExpletusSansBold";
    src: local("ExpletusSansBold"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansRegular";
    src: local("ExpletusSansRegular"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Regular.ttf") format("truetype");
}

.cardContainer {
    width: 90px;
    height: 130px;
    flex-shrink: 0;
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    padding-bottom: 10px;
    cursor: pointer;
}

.cardContainer>div p {
    font-size: 10px;
    margin: 0px;
    margin-left: 5px;
}

.cardContainer>div :nth-child(1) {
    margin-top: 2px;
    font-family: "ExpletusSansRegular";
}

.cardContainer>div :nth-child(2) {
    font-family: "ExpletusSansBold";
}

.cardImage {
    width: 100%;
    height: 70%;
    object-fit: cover;
}

@media (min-width: 760px) {}

@media (min-width: 1020px) {

    .cardContainer {
        width: 150px;
        height: 200px;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: white;
        overflow: hidden;
        padding-bottom: 10px;
        cursor: pointer;
    }

}
