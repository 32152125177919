.container {
    width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bm-content-wrapper-A {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bm-page-title-A {
    margin: 0%;
    color: #1F1F1F !important;
    font-size: var(--xmd);
    margin-top: var(--xmd);
}

.bm-page-title-B {
    margin: 0%;
    color: #1F1F1F !important;
    font-family: var(--FontFamily-ExpletusSansBold);
    font-size: var(--xmd);
    margin-top: var(--xmd);
}


.grp_1 {
    text-align: center;
    margin-top: var(--xmd);
    padding-left: var(--xmd);
    padding-right: var(--xmd);
}

.factureImg {
    height: 100px;
    width: auto;
    object-fit: cover;
    margin-top: var(--xmd);
}

.txt1 {
    margin: 0%;
    text-align: center;
    color: var(--indigo-c500);
}

.txt2 {
    margin: 0%;
    text-align: center;
    color: #1F1F1F;
    font-size: var(--smd);
    font-style: italic;
}

.bm-wondload-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* width: 100%; */
    margin-bottom: var(--xmd);
    /* margin-right: 10px; */
}

.bm-bill-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--xmd);
    margin-bottom: var(--xmd);
}

.bm-thanks-text-A {
    font-size: var(--xmd);
    font-weight: 700;
    line-height: var(--l);
}

.bm-thanks-text-B {
    font-size: var(--xmd);
    font-weight: 700;
    line-height: var(--l);
}

.bm-paragraph-text-A {
    font-size: var(--xxmd);
}

.bm-paragraph-text-B {
    font-size: var(--xxmd);
    color: var(--black) !important;
}

.bm-img-succes-container-A,
.bm-img-succes-container-B {
    height: 220px;
    width: 100%;
    background-image: url("../../assets/svgImages/orderChecked.svg");
    background-size: cover;
    background-color: var(--indigo-c500);
    background-repeat: no-repeat;
    background-position: center;
    margin-top: var(--xmd);
    margin-bottom: var(--xmd);
}

.bill {
    width: 100%;
    height: var(--xmd-giant);
    background-image: url("../../assets/img/facture.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}

@media (min-width: 760px) {

    .grp_1 {
        margin-top: var(--xl);
    }

    .factureImg {
        margin-top: var(--xl);
    }

    .txt1 {
        font-size: var(--xmd);
    }

    .txt2 {
        font-size: var(--xxmd);
    }

}

@media (min-width: 1024px) {
    .bm-content-wrapper-B {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        padding-right: 20%;
        margin-top: 10%;
    }

    .bm-img-succes-container-A {
        height: var(--giant);
        width: var(--xxbig);
    }

    .bm-img-succes-container-B {
        display: none;
    }

    .grp_1 {
        margin-top: var(--xmd);
    }

    .factureImg {
        margin-top: var(--xmd);
    }

    .bm-thanks-text-A {
        font-size: var(--md) !important;
    }

    .bm-thanks-text-B {
        font-size: var(--xl) !important;
        font-family: var(--FontFamily-ExpletusSansBold);
    }

    .bm-paragraph-text-A {
        font-size: var(--xxmd);
    }

    .bm-paragraph-text-B {
        font-size: var(--xmd) !important;
        padding-left: 10%;
        padding-right: 10%;
        font-family: var(--FontFamily-ExpletusSansRegular);
        margin-top: var(--l);
    }
}