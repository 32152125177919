.container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {



}
