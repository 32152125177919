.bm-container-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var( --indigo-c500);
}

.bm-container-wrapper-A {
    background-color: var(--grey-c100);
    border-radius: var(--xxl);
    color: var( --indigo-c500);
}

.bm-qty-number {
    margin-left: var(--s);
    margin-right: var(--s);
}

.bm-qty-number-A {
    color: var( --indigo-c500);
    font-weight: bold;
}

.bm-qty-number-B {
    color: var(--orange-c400);
    font-weight: bold;
}

.bm-container-wrapper-B {
    display: flex;
    flex-direction: row;
    border: var(--unit) solid var(--orange-c300);
    border-radius: var(--xs);
    justify-content: center;
    align-content: center;
    justify-content: space-between;
    color: var(--black);
}

.bm-qty-up-down-container {
    display: flex;
    padding: var(--xxmd);
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bm-qty-up-down-container:hover {
    background-color: var(--orange-c400);
}

.bm-qty-up-down-container-A {
    background-color: var(--red-c400);
}

.bm-qty-up-down-container-A:hover {
    background-color: var(--red-c500);
}

.bm-qty-up-down-container-B {
    /* background-color: var(--black); */
    border: none;
}

.btn-xs-A {
    width: var(--s);
    height: var(--s);
}

.btn-sm-A {
    width: var(--xl);
    height: var(--xl);
}


.btn-md-A {
    width: var(--xxl);
    height: var(--xxl);
}

.btn-xl-A {
    width: var(--huge);
    height: var(--huge);
}