.bm-button {
    margin: var(--s);
    height: var(--xxl);
    min-width: var(--xxl);
    color: var(--white);
    font-family: var(--FontFamily-AvenirNextDemiBold);
    cursor: pointer;
    border: none !important;
}

.bm-button:focus {
    outline: none;
    border: none;
    box-shadow: none;
}


.bm-button-A {
    background-color: var(--red-c400);
    border-radius: var(--xxl);
}

.bm-button-A:hover {
    background-color: var(--red-c500);
}

.bm-button-B {
    background-color: var(--orange-c300);
    border-radius: var(--s);
    font-family: var(--FontFamily-ExpletusSansBold);
}

.bm-button-B:hover {
    background-color: var(--orange-c400);
}