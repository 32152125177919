.bm_paragraph {
    margin: 0%;
}

.bm-paragraph-A {
    color: var(--indigo-c500) !important;
}

.bm-paragraph-B {
    color: var(--orange-c400) !important;
}

.bm_paragraph_bold {
    font-family: var(--FontFamily-AvenirNextDemiBold);
}

.bm_paragraph_regular {
    font-family: var(--FontFamily-AvenirNextRegular);
}

.bm_paragraph_light {
    font-family: var(--FontFamily-AvenirNextLight);
}