@font-face {
    font-family: "ExpletusSansBold";
    src: local("ExpletusSansBold"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansRegular";
    src: local("ExpletusSansRegular"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Regular.ttf") format("truetype");
}

.logo{
    width: 200px;
    height: 100px;
    margin-bottom: 20px;
    object-fit: contain;
}

.bymeLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 33%;
}

.container {
    display: flex;
    flex-direction: column;
    background-color: "#FAFAFA";
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.footerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}


.footerRow :nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .footerRow :nth-child(3) {
 width: 10%;
} */
.newsLetterStyle {
    font-family: "ExpletusSansBold";
    font-size: 20px;
    text-align: center;
    margin-top: 100px;
}

.contactIconRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.inputStyle {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.contactIcon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 10px;
}

.contactIcon:active {
    background-color: #c9c3c2;
    border-radius: 100%;
}

.menuRow {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.menuRow>div {
    font-family: 'ExpletusSansRegular';
    border-bottom: 1px solid #232323;
    color: #232323;
    cursor: pointer;
    font-size: 15px;
}

.footer {
    text-align: center;
    padding-bottom: 20px;
}



.entrepriseText {
    color: #748194;
    font-size: 15px;
    font-family: "ExpletusSansBold";
}

.copyright {
    color: #000;
    font-size: 15px;
    font-family: "ExpletusSansRegular";
}

.byme {
    color: #2A7BE4;
    font-size: 15px;
    font-family: "ExpletusSansRegular";
}

.socials {
    display: flex !important;
    width: 100px !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between;
}