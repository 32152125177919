.container {
    height: 30px;
    border: transparent;
    outline: none;
    background-color: #EEEEEE;
    color: #545454;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 30px;
    margin-bottom: 10px;
}