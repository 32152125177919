.container {
    position: relative;
    overflow: hidden;
    height: var(--big);
    padding-top: var(--xmd);
    /* background-color: #0000002c; */
    background: none;
}

.activeCustomSlide {
    position: relative;
    z-index: 99;
    width: var(--xh);
    height: var(--xh);
    border-radius: var(--xh);
    margin-left: var(--s);
    margin-right: var(--s);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.customSlide {
    position: relative;
    z-index: 99;
    width: var(--huge);
    height: var(--huge);
    border-radius: var(--huge);
    margin-left: var(--s);
    margin-right: var(--s);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.itemImgStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlayCircle {
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: solid 5px #000000;
    width: var(--xxh);
    height: var(--xxh);
    border-radius: var(--xxh);
}

@media (min-width: 760px) {
    


}

@media (min-width: 1020px) {



}
