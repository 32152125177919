@import url('@byme-ui-styles/fonts.css');

.payment-card-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: var(--s);
    padding-right: var(--s);
}

.bm-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;

}

/* Description */
.bm-p {
    margin-top: var(--s);
    text-align: left;
}

/* Title */
.title-A {
    color: var(--purple-c300);
    font-family: var(--FontFamily-AvenirNextDemiBold);
}

.title-B {
    color: var(--black) !important;
    font-family: var(--FontFamily-ExpletusSansSemiBold);
}

/* Checkbox */
.checkbox-A .p-checkbox-box {
    border: var(--unit) solid var(--indigo-c500);
}

.bm-checkbox-checked-A .p-checkbox-box {
    background-color: var(--indigo-c500);
}

.bm-checkbox-checked-B .p-checkbox-box {
    background-color: var(--orange-c400);
}

.checkbox-B .p-checkbox-box {
    border: var(--unit) solid var(--orange-c400);
}

.checkbox-B .p-checkbox-box {}