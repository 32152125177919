.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.rightContentContainer {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightContentContainerDisplayLeftArrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconContainer{
    cursor: pointer;
    width: 50px;
}

.CartIcon {
    margin-left: 20px;
}

@media (min-width: 768px) {
    
}

@media (min-width: 1024px) {
    
}