.container-A {
    background-color: var(--indigo-c500);
    margin-top: var(--l);
}

.container-B {
    background-color: var(--grey-c100);
}

.bm-content-container-A,
.bm-content-container-B {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    align-content: center;
}

.input-container-wrapper {
    display: flex;
    justify-content: center;
    margin-top: var(--xmd);
}

.bm-delivery-bg-A,
.bm-delivery-bg-B {
    width: 100%;
    height: 250px;
    background-size: cover;
    background-color: black;
    background-image: url('../../assets/svgImages/deliveryImg.svg');
    background-repeat: no-repeat;
    background-position: center;
}

.bm-delivery-bg-B {
    background-image: url('../../assets/img/livraison.desktop.bg.png');

}

.bm-delivery-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
}

.topImage {
    width: 100%;
    height: 182px;
}

@media (min-width: 760px) {
    .topImage {
        width: 100%;
        height: 315px;
    }

    .bm-delivery-input-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-top: 40px;
    }

    .bm-delivery-bg-B {
        height: 350px;
    }
}

@media (min-width: 1020px) {

    .bm-content-container-A,
    .bm-content-container-B {
        display: flex;
        flex-direction: row-reverse;
        height: inherit;
        justify-content: space-between;
        margin-top: var(--s);
    }

    .bm-content-container-B {
        flex-direction: row;
    }

    .input-container-wrapper {
        display: flex;
        width: 40%;
    }

    .img-container-wrapper {
        display: flex;
        width: 60%;
    }

    .bm-delivery-bg-A,
    .bm-delivery-bg-B {
        width: 100%;
        height: inherit;
        border-top-right-radius: var(--xmd);
    }

    .bm-delivery-bg-B {
        border-top-right-radius: 0;
        border-top-left-radius: var(--xmd);
    }

    .bm-delivery-input-container {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 40px;
        align-items: center;
    }
}