.container {
    flex: 1;
    width: 100%;
}

.pageTitle {
    color: var(--indigo-c500);
    font-size: var(--xmd);
    text-align: center;
    margin-right: var(--xxh);
    margin-top: var(--s);
}

.emptyBasketText {
    align-self: center;
    justify-content: center;
    align-items: center;
    color: inherit;
    font-size: var(--md);
    font-weight: 300;
    text-align: center;
}

.basketContainer {
    margin-top: var(--xl);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: var(--giant);
}

.fixedBottomContainer {
    position: fixed;
    z-index: 9999;
    bottom: var(--xmd);
    width: 100%;
    display: flex;
    justify-content: center;
}

.fixedBottomSectionContainer {
    width: 300px;
    border: solid var(--xxs) var(--red-c500);
    background-color: var(--white);
    border-radius: var(--xmd);
}

.addCartImgContainer {
    display: flex;
    justify-content: center;
    margin-top: -25px;
}

.addCartImg {
    width: var(--xxl);
}

.fixedBottomSectionSubContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: var(--xmd);
}

.totalContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--s);
    margin-bottom: var(--s);
}

.totalLabel {
    margin: 0%;
    margin-left: var(--s);
    font-size: var(--smd);
    color: var(--indigo-c500);
}

.totalAmmount {
    margin: 0%;
    margin-right: var(--s);
    font-size: 16px;
    color: var(--indigo-c500);
}

@media (min-width: 760px) {

    .pageTitle {
        font-size: var(--l);
        margin-right: 220px;
        margin-top: var(--xxl);
    }

    .basketContainer {
        margin-top: var(--xxl);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: var(--mgiant);
    }


    .fixedBottomSectionContainer {
        width: 500px;
        border-radius: var(--l);
    }

    .addCartImgContainer {
        margin-top: -35px;
    }

    .addCartImg {
        width: var(--mhuge);
    }

    .totalContainer {
        margin-top: var(--xmd);
        margin-bottom: var(--xmd);
    }

    .totalLabel {
        margin-left: var(--xmd);
        font-size: 16px;
    }

    .totalAmmount {
        margin-right: var(--xmd);
        font-size: 22px;
    }

}

@media (min-width: 1020px) {

    .desktopHeaderContainer {
        position: fixed;
        z-index: 9999;
        top: 0%;
        width: 100%;
        background-color: var(--white);
    }

    .pageTitleDestop {
        text-align: center;
        margin: 0%;
        color: var(--indigo-c500);
        font-size: var(--l);
    }

    .basketContainer {
        margin-top: var(--giant);
        margin-left: var(--xl);
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-bottom: 0%;
    }

    .fixedBottomContainer {
        width: 40%;
        bottom: 45%;
        right: var(--xmd);
        justify-content: flex-end;
    }

    .fixedBottomSectionContainer {
        /* margin-right: var(--xl); */
        width: 100%;
    }

    .desktopIconLeft {
        width: var(--huge);
        margin-left: var(--xmd);
        margin-top: var(--xmd);
    }

}