.carousel-wrapper {
  position: relative;
}

.carousel-container {
  position: relative;
  overflow-x: auto;
  scrollbar-width: none;
}

.carousel-content {
  display: flex;
}

.left-arrow,
.right-arrow {
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  background-color: var(--white);
  box-shadow: 0 4px var(--ms) rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: var(--xmd);
  cursor: pointer;
}

.left-arrow {
  left: 0;
  margin-left: var(--s);
}

.right-arrow {
  right: 0;
  margin-right: var(--s);
}

.bullets-container {
  display: flex;
  justify-content: center;
  margin-top: var(--s);
}

.bullet {
  width: var(--xmd);
  height: var(--xmd);
  border-radius: 50%;
  border: var(--unit) solid #ccc;
  background-color: transparent;
  margin-right: var(--xs);
  cursor: pointer;
}

.bullet-active {
  background-color: var(--indigo-c500);
}
