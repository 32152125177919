/* @import url('@byme-ui-styles/fonts.css'); */

/* Default is Template a */
.bm-input-container-A {
    justify-content: center;
    border: none;
    min-height: var(--xxl);
    margin: var(--xs);
    padding: var(--s);
    padding-left: var(--xxmd);
    border-radius: var(--xxl);
    font-family: var(--FontFamily-AvenirNexRegular);
    caret-color: var(--indigo-c500);
    border: 1px solid var(--grey-c500);
    box-shadow: none;
    color: var(--indigo-c500);
}

.bm-input-container-A:focus {
    outline: none;
    border: 1.5px solid var(--indigo-c500);
}

.bm-input-area-container-A {
    border-radius: var(--s);
    padding: var(--s);
    margin: var(--xs);
    padding-left: var(--xxmd);
    font-family: var(--FontFamily-AvenirNexRegular);
    caret-color: var(--indigo-c500);
    color: var(--indigo-c500);
}

.bm-input-area-container-A:focus {
    outline: none;
    border: 1.5px solid var(--indigo-c500);
}


/* Template B */

.bm-input-container-B {
    border-radius: 0px;
    padding: var(--s);
    margin: var(--xs);
    padding-left: var(--xxmd);
    caret-color: var(--orange-c300);
    border: 1px solid var(--grey-c500);
    font-family: var(--FontFamily-ExpletusSansSemiBold) !important;
}

.bm-input-container-B:focus {
    outline: none;
    border: 1.5px solid var(--orange-c300);
}

.bm-input-area-container-B {
    border-radius: 0px;
    padding: var(--s);
    margin: var(--xs);
    padding-left: var(--xxmd);
    caret-color: var(--orange-c300);
    font-family: var(--FontFamily-ExpletusSansSemiBold) !important;
}

.bm-input-area-container-B:focus {
    outline: none;
    border: 1.5px solid var(--orange-c300);
    font-family: var(--FontFamily-ExpletusSansSemiBold) !important;
}