@keyframes beat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.stepper-container {
    display: flex;
    align-items: center;
}

.stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 50px;
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step {
    display: flex;
    justify-content: center;
    text-align: center;
    min-width: 50px;
    padding: 10px;
    font-size: 10px;
    border-radius: 20px;
    font-weight: 600;
    transition: background-color 0.5s ease;
}

.step.completed {
    background-color: #FF885A;
    color: white;
}

.step.active {
    background-color: #150554;
    color: white;
    animation: beat 1s infinite;
}

.step.upcoming {
    background-color: lightgrey;
    color: white;
}

.separator {
    height: 50px; 
    border-left: 2px solid black;
    width: 0;
    transition: border-color 0.5s ease;
}

.separator.completed {
    border-color: #FF885A;
}

.separator.upcoming {
    border-color: lightgrey;
}

.content {
    flex-grow: 1;
}
