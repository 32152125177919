.card-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    width: var(--big);
    height: var(--xxxbig);
    margin: var(--s);
    padding: 0;
    flex-shrink: 0; /* Empêche le rétrécissement du contenu */
}

.bm-card-wrapper {
    height: var(--big) !important;
    margin: 0;
    padding: var(--xs);
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px var(--ms) rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.bm-card-wrapper-selected-A {
    box-shadow: 0 4px var(--ms) var(--indigo-c500-rgba) !important;
}
.bm-card-wrapper-selected-B {
    box-shadow: 0 4px var(--ms) var(--orange-c300-rgb) !important;
}

.bm-img-wrapper {
    background-color: var(--grey-c100);
    width: var(--xxl);
    height: var(--xxl);
    border-radius: var(--xxl);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bm-card-img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.bm-card-title {
    cursor: pointer;
    text-align: center;
    margin-top: var(--s);
}