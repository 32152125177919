.sideDishContainer {
    padding-top: 20px;
    padding-bottom: 20px;
}

.sideDishSubContainer {
    display: flex;
    align-items: center;
}

.sideDishTxtContainer {
    font-size: 12px;
    color: #fff;
    background-color: #ffffff45;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 20px;
}

.line {
    flex: 1;
    margin-left: 10px;
    border: solid 1px #B3B3B3;
}

.sideDishItemList {
    width: 100%;
    /* margin-left: 20%; */
}