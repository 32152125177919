.bm-checkbox-container-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}

.bm-checkbox-label {
  margin-left: var(--xs);
}

.p-checkbox {
  margin: var(--xs)
}

.p-checkbox-box {
  border: none;
  border-radius: var(--xs)
}