body {
    font-family: var(--FontFamily-AvenirNextDemiBold);
}

.bm-product-card-container-A {
    margin: var(--s);
    display: flex;
    flex-direction: column;
    width: var(--mgiant);
    height: var(--xxmd-giant) !important;
    padding: 0;
}

.bm-product-card-container-B {
    margin: var(--s);
    display: flex;
    flex-direction: column;
    width: var(--xxmd-giant) !important;
    height: var(--xxmd-gian);
    font-family: var(--FontFamily-ExpletusSansSemiBold);
}

.bm-product-card-image-wrapper-A {
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: var(--FontFamily-AvenirNextDemiBold);
}


.bm-product-img-container-A {
    position: relative;
    height: var(--xxmd-giant) !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bm-product-img-container-B {
    height: var(--mgiant);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--s);
}

.bm-product-card-text-block-A {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: inherit;
    height: inherit;
}

.bm-product-card-text-block-B {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--xmd);
}

.bm-hide-product-name-A {
    display: none;
}

.bm-product-item-descirption-A {
    display: none;
}

.bm-product-item-descirption-B {
    font-size: var(--s);
    margin-top: 4px;
    margin-left: 0px;
}

.bm-product-card-text-block-A>:nth-child(1) {
    display: flex;
    flex-direction: column-reverse;
    padding-right: var(--s);
    width: 75%;
    justify-content: space-between;
    height: inherit;
    align-content: flex-end;
}

.bm-product-card-text-block-A>:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: inherit;

}

.bm-product-card-text-block-B>:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.bm-product-card-text-block-B>:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: inherit;

}

.icon-column-B {
    display: flex;
    flex-direction: column;
    height: var(--xxbig) !important;
    background-color: reds;

}

.bm-product-item-container-B {
    padding-top: var(--s);
    padding-left: 17px;
    padding-right: 17px;
    padding-bottom: var(--xmd);
    background-color: var(--white);
    box-shadow: var(--unit) 0.5px var(--ms) rgba(0, 0, 0, 0.2);
    min-width: var(--xxmd-giant);
    max-width: var(--l-giant);
    max-height: var(--xl-giant);
    border-radius: var(--ms);
    margin-top: var(--s);
    margin-left: var(--s);
    margin-right: var(--s);
    text-align: left;
}

.bm-product-item-price-A {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-size: var(--smd);
    padding: var(--s);
    padding-right: var(--xmd);
    background-color: var(--indigo-c500);
    border-top-right-radius: var(--xmd);
    border-bottom-right-radius: var(--xmd);
    color: var(--white);
}

.bm-product-item-name-A {
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: var(--smd);
    padding: var(--s);
    padding-right: var(--xmd);
    margin-bottom: var(--s);
    background-color: var(--indigo-c500);
    border-top-right-radius: var(--xmd);
    border-bottom-right-radius: var(--xmd);
    color: var(--white);
}

.bm-button-action-container-A {
    cursor: pointer;
    position: absolute;
    right: var(--xs);
    bottom: var(--md);
    width: 70%;
    height: var(--xmd);
}

.bm-button-action-plus-container-A {
    cursor: pointer;
    position: absolute;
    justify-content: center;
    /* width: var(--xl);
    height: var(--xl); */
    border-radius: 50%;
    padding: var(--xxmd);
    background-color: var(--red-c400);
    right: var(--xs);
    bottom: var(--s);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bm-button-action-plus-container-B i {
    color: var(--orange-c400) !important;
    font-size: var(--xmd) !important;
    font-weight: 500;
    padding: var(--s);
}

.bm-basket-container-A {
    position: absolute;
    top: 10%;
    right: 5%;
    padding: var(--s);
    border-radius: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.bm-basket-container-B {
    display: none;
}

@media (min-width: 768px) {
    .bm-product-card-container-B {
        width: var(--l-giant);
    }
    .bm-product-card-container-A {
        width: var(--xmd-giant);
    }
    .bm-button-action-container-A {
        position: absolute;
        right: var(--xs);
        bottom: 45px;
        width: 50%;
        height: var(--xmd);
    }

    .bm-product-item-price-A {
        font-size: var(--xxmd);
        padding: var(--s);
        padding-right: var(--s);
        padding-left: var(--s);
        border-top-right-radius: var(--xl);
        border-bottom-right-radius: var(--xl);
    }

    .bm-product-item-name-A {
        font-size: var(--xxmd);
        padding: var(--s);
        padding-right: var(--s);
        padding-left: var(--s);
        border-top-right-radius: var(--xl);
        border-bottom-right-radius: var(--xl);
    }
}

@media (min-width: 1024px) {
    .bm-product-card-container-A {
        width: var(--xmd-giant);
        height: var(--l-giant) !important;
    }

    .bm-product-img-container-A {
        height: var(--l-giant) !important;
    }

    .bm-button-action-container-A {
        position: absolute;
        right: var(--xs);
        bottom: 35px;
        width: 60%;
        height: var(--xmd);
    }

    .bm-product-item-price-A {
        font-size: 14px;
        padding: var(--s);
        padding-right: var(--xmd);
        padding-left: var(--xmd);
        border-top-right-radius: var(--xmd);
        border-bottom-right-radius: var(--xmd);
    }

    .bm-product-item-name-A {
        font-size: 14px;
        padding: var(--s);
        padding-right: var(--xmd);
        padding-left: var(--xmd);
        border-top-right-radius: var(--xmd);
        border-bottom-right-radius: var(--xmd);
    }

    .bm-product-card-container-B {
        width: var(--l-giant);
    }

}