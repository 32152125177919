@font-face {
    font-family: "ExpletusSansBold";
    src: local("ExpletusSansBold"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "ExpletusSansRegular";
    src: local("ExpletusSansRegular"),
        url("../../../../assets/fonts/expletus/ExpletusSans-Regular.ttf") format("truetype");
}

.container {
    display: flex;
    flex-direction: column;
    background-color: "#FAFAFA";
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 40px;
}

.separator {
    width: 100%;
    margin: 0 auto;
    height: 1px;
    background-color: #cecbcb;
    margin-top: 20px;
    margin-bottom: 20px;
}

.newsLetterStyle {
    font-family: "ExpletusSansBold";
    font-size: 20px;
    text-align: center;
}

.contactIconRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.inputStyle {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.contactIcon {
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding: 10px;
}

.contactIcon:active {
    background-color: #c9c3c2;
    border-radius: 100%;
}

.menuRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.menuRow>div {
    font-family: 'ExpletusSansRegular';
    border-bottom: 1px solid #232323;
    color: #232323;
    cursor: pointer;
    font-size: 15px;
}

.footer {
    text-align: center;
    padding-bottom: 20px;
}

.entrepriseText {
    color: #748194 ;
    font-size: 15px;
    font-family: "ExpletusSansBold";
}
.copyright {
    color: #000 ;
    font-size: 15px;
    font-family: "ExpletusSansRegular";
}
.byme {
    color: #2A7BE4 ;
    font-size: 15px;
    font-family: "ExpletusSansRegular";
}
@media (min-width: 760px) {}

@media (min-width: 1020px) {}