.container-A,
.container-B {
    background-color: transparent !important;
}

.bm-delivery-divider-A {
    display: none;
}

.bm-delivery-divider-B {
    display: none;
}

.bm-floating-card-A {
    display: none;
}

.bm-wrapperStyle-A {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    flex-direction: column;
    overflow: auto;
}

.bm-wrapperStyle-B {
    flex-direction: column;
    overflow: auto;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0;
    margin-right: 0;
}

.mobileContentContainer {}

.bm-order-refs-A {
    display: none;
}

.bm-order-refs-B {
    text-align: right;
    margin-left: auto;
    font-family: var(--FontFamily-ExpletusSansRegular);
    padding-right: 20px;
}

.bm-order-refs-B p {
    font-size: 12px;
}

.bm-order-refs-dsc-A {
    display: none;
}

.bm-img-wrapper-A {
    display: flex;
    justify-content: center;
}

.bm-img-wrapper-B {
    display: none;
}

.bm-order-delivery-image-A {
    background-image: url('../../assets/svgImages/RecapIconImg.svg');
    width: var(--giant);
    height: var(--giant);
    background-size: cover;
    background-color: var(--indigo-c500);
    background-repeat: no-repeat;
    background-position: center;
}

.bm-order-recap-container-A,
.bm-order-recap-container-B {
    display: flex;
    justify-content: space-between;
    margin-top: var(--xl);
}

.bm-order-recap-container-B {
    width: 100%;
}

.bm-order-details-left-container-A {
    margin-left: var(--s);
}

.bm-order-details-left-container-B {}

.bm-order-details-right-container-A {
    margin-right: var(--s);
}

.bm-order-details-right-container-B {}

.bm-order-details-title-A,
.bm-order-details-title-B {
    border-bottom: solid var(--unit) var(--indigo-c500);
    padding-bottom: var(--s);
    display: flex;
    justify-content: flex-start;
    margin-bottom: var(--s);
}

.bm-order-details-title-B {
    border-bottom: solid var(--unit) var(--orange-c400);
}

.bm-order-details-title-A label {
    color: var(--indigo-c500);
    font-size: 18px;
}

.bm-order-details-title-B label {
    color: var(--orange-c400);
}

.bm-order-details-item-A,
.bm-order-details-item-B {
    margin: 0%;
    margin-bottom: var(--s);
    font-size: var(--smd);
    color: var(--indigo-c500);
}

.bm-order-details-item-B {
    color: var(--black-night);
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.bm-order-delivery-total-label-A {
    margin: 0%;
    margin-top: 32px;
    /* color: var(--indigo-c500); */
}

.bm-order-delivery-total-label-B,
.bm-order-delivery-total-value-B {
    margin-top: var(--l);
    text-align: center;
    font-size: 17px;
    font-weight: 400;
    color: var(--black-night) !important;
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.bm-order-delivery-total-value-A {
    margin: 0%;
    margin-top: var(--xmd);
    color: var(--red-c400) !important;
}

.bm-order-delivery-total-value-B {
    font-family: var(--FontFamily-ExpletusSansBold);
}

.bm-order-delivery-btn-container-A {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--xmd);
}

.bm-order-delivery-btn-container-B {
    display: none;
}

.bm-order-delivery-details-container-A {
    background-color: var(--indigo-c500);
    margin-top: var(--l);
    margin-bottom: var(--s);
    margin-left: var(--s);
    margin-right: var(--s);
    border-radius: var(--xl);
    padding-top: var(--xl);
    padding-left: var(--s);
    padding-right: var(--s);
}

.bm-order-delivery-details-title-A {
    text-align: center;
    margin: 0;
    color: var(--white) !important;
    font-size: 16px;
    margin-bottom: var(--xmd);
}

.bm-order-delivery-details-title-B {
    font-family: var(--FontFamily-ExpletusSansRegular);
    font-size: 15px;
    margin-top: 20px;
    font-weight: 700;
}

.bm-order-delivery-details-text-A {
    margin: 0%;
    margin-bottom: var(--xs);
    font-size: var(--smd);
    color: var(--white) !important;
}

.bm-order-delivery-details-text-B {
    margin: 0;
    margin-top: var(--xs);
    margin-bottom: var(--xs);
    font-size: var(--xxmd);
    color: var(--black-night) !important;
    font-family: var(--FontFamily-ExpletusSansRegular);
}

.bm-floating-recap-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
}


.bm-floating-recap-content p {
    text-align: center;
    font-size: 14px;
    font-family: 'ExpletusSansRegular';
}

@media (min-width: 760px) {
    .mobileContentContainer {
        padding-left: 20px;
        padding-right: 20px;
    }

    .bm-wrapperStyle-B {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 40px;
        padding-right: 40px;
    }

    .bm-order-details-left-container-A {
        margin-left: var(--xl);
    }

    .bm-order-details-right-container-A {
        margin-right: var(--xl);
    }

    .bm-order-details-title-A {
        margin-bottom: var(--xmd);
    }

    .bm-order-details-title-A label {
        font-size: 26px;
    }

    .bm-order-details-item-A,
    .bm-order-details-item-B {
        font-size: var(--xmd);
    }

    .bm-order-delivery-total-label-A {
        margin-top: var(--xl);
        font-size: var(--l);
    }

    .bm-order-delivery-total-value-A {
        margin-top: var(--xl);
        font-size: var(--l);
    }

    .bm-wrapperStyle-A {
        padding-left: var(--s);
        padding-right: var(--s);
    }

    .bm-order-delivery-details-container-A {
        margin-top: var(--xxl);
        border-top-right-radius: var(--huge);
        border-top-left-radius: var(--huge);
        border-bottom-right-radius: var(--huge);
        border-bottom-left-radius: var(--huge);
        padding-top: var(--xxl);
        padding-left: var(--xl);
        padding-right: var(--xl);
    }

    .bm-order-delivery-details-title-A {
        font-size: 25px;
        margin-bottom: var(--l);
    }

    .bm-order-delivery-details-text-A {
        margin-bottom: var(--s);
        font-size: 16px;
    }

}

@media (min-width: 1020px) {
    .container-B {
        display: flex;
        flex-direction: row;
        width: 50%;
    }

    .bm-order-refs-dsc-B {
        margin-left: auto;
        padding-left: 20px;
    }

    .bm-order-refs-dsc-B p {
        font-size: 12px;
        font-family: "ExpletusSansRegular";

    }

    .mobileContentContainer {
        width: 50%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .bm-order-recap-container-A {
        justify-content: center;
        margin-top: var(--xmd);
    }

    .bm-order-recap-container-B {
        justify-content: space-between !important;
    }

    .bm-order-details-left-container-A,
    .bm-order-details-left-container-B {
        margin-left: 0;
        margin-right: var(--xl);
    }

    .bm-order-details-right-container-A,
    .bm-order-details-right-container-B {
        margin-right: 0;
        margin-left: var(--xl);
    }

    .bm-order-details-title-A label {
        font-size: 18px;
    }

    .bm-order-details-item-A,
    .bm-order-details-item-B {
        font-size: var(--xxmd);
    }

    .bm-order-delivery-total-label-A {
        margin-top: 23px;
        font-size: var(--xmd);
    }

    .bm-order-delivery-total-value-A {
        margin-top: 23px;
        font-size: var(--xmd);
    }

    .bm-order-delivery-details-container-A {
        align-self: center;
        width: 40%;
        margin-top: var(--xxl);
        border-radius: var(--xl);
        padding: var(--xmd);
        /* margin-left: 34%; */
    }

    .bm-order-delivery-details-title-A {
        font-size: 18px;
        margin-bottom: var(--xmd);
    }

    .bm-order-delivery-details-text-A {
        margin-bottom: var(--xs);
        font-size: var(--smd);
    }

    .bm-delivery-divider-B {
        display: block;
        padding-bottom: 20px;
    }

}