.productItemContainer {
    flex: 1;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    margin: 5px;
    background-color: #ffffff;
    /* -webkit-box-shadow: 1px 1px 10px -2px lightgrey; 
    box-shadow: 1px 1px 10px -2px lightgrey; */
}

.productItemHeadContainer {
    width: 30%;
    height: 120px;
    background-color: #FAFAFA;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: end;
}

.productItemBodyContainer {
    flex: 1;
    padding: 10px;
}

.productInfosContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.productItemName {
    margin: 0%;
    font-size: 12px;
    margin-bottom: 5px;
    color: #333542;
}

.productItemDescription {
    margin: 0%;
    font-size: 10px;
    margin-bottom: 10px;
    color: #6B7C93;
}

.productItemPrice {
    margin: 0%;
    text-align: right;
    font-size: 14px;
    color: #0A0C0B;
    font-weight: 700;
    margin-bottom: 10px;
}

.actionButtonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.quantityContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin-right: 20px;
    padding: 5px;
    border-radius: 20px;
    border: solid 1px #150554;
}

.quantityDown {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #150554;
    font-weight: 800;
    border: solid 2px #150554;
    background-color: #fff;
}
.quantityDown label {
    margin: 0%;
    padding: 0%;
}

.quantity {
    color: #37343B;
    margin: 0%;
    padding: 0%;
}

.quantityUp {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #fff;
    font-weight: 800;
    border: solid 2px #150554;
    background-color: #150554;
}
.quantityUp label {
    margin: 0%;
    padding: 0%;
}

@media (min-width: 768px) {
    
}

@media (min-width: 1024px) {
    
}